import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api';

export const getAll = createAsyncThunk('user/getAll', async (data, { rejectWithValue }) => {
  try {
    const response = await api.get('/banks', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const createNew = createAsyncThunk('user/createNew', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post('/banks', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const updateInfo = createAsyncThunk('user/updateInfo', async (data, { rejectWithValue }) => {
  try {
    const response = await api.put('/banks', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const deleteBank = createAsyncThunk('user/deleteBank', async (data, { rejectWithValue }) => {
  try {
    const response = await api.patch('/banks', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});
const BankSlice = createSlice({
  name: 'bank',
  initialState: {
    data: [],
    isLoading: false,
  },
  reducers: {},
  extraReducers: {
    [getAll.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAll.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    [getAll.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});
export default BankSlice.reducer;
