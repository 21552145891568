import { ArrowUpOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { Card, Col, DatePicker, Space, Statistic, Table, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAll, getByDay } from '../features/statistic/statisticSlice';
import { getAllUser, getNewUser } from '../features/user/userSlice';
import { formatMoney } from '../utils';
const Home = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState([]);
  const [newUser, setNewUser] = useState([]);
  const [statistic, setStatistic] = useState({});

  const fetchData = async () => {
    const users = await dispatch(getAllUser()).then(unwrapResult);
    setUser(users);

    const newUsers = await dispatch(getNewUser()).then(unwrapResult);
    setNewUser(newUsers);

    const statisticData = await dispatch(getAll()).then(unwrapResult);
    setStatistic(statisticData);
  };

  const fetchStatisticByDate = async (from, to) => {
    const statisticData = await dispatch(getByDay({ from, to })).then(unwrapResult);
    setStatistic(statisticData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: '',
      key: '',
      render: (text, record, index) => index + 1,
      width: '5%',
    },
    {
      title: 'Tài khoản',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Số dư',
      key: 'balance',
      dataIndex: 'balance',
      render: (text) => {
        return <Tag color='blue'>{formatMoney(text)}</Tag>;
      },
    },
    {
      title: 'Ngày tạo',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (text, record) => <Space size='middle'>{moment(text).format('HH:mm DD/MM/YYYY')}</Space>,
    },
  ];

  const columnsHistoryBet = [
    {
      title: 'ID',
      dataIndex: '',
      key: '',
      render: (text, record, index) => index + 1,
      width: '5%',
    },
    {
      title: 'Tài khoản',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Số tiền',
      key: 'betAmount',
      dataIndex: 'betAmount',
      render: (text) => {
        return <Tag color='blue'>{formatMoney(text)}</Tag>;
      },
    },
    {
      title: 'Kết quả',
      key: 'betResult',
      dataIndex: 'betResult',
      render: (text) => {
        const win = text === 'WIN';
        return <Tag color={win ? 'green' : 'red'}>{win ? 'THẮNG' : 'THUA'}</Tag>;
      },
    },
    {
      title: 'Ngày tạo',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (text, record) => <Space size='middle'>{moment(text).format('HH:mm DD/MM/YYYY')}</Space>,
    },
  ];

  return (
    <div className='site-statistic-demo-card'>
      <div className='flex'>
        <Col span={12}>
          <Card>
            <Statistic
              title='Thành viên mới'
              value={newUser.length}
              valueStyle={{
                color: '#3f8600',
              }}
              prefix={<ArrowUpOutlined />}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title='Tất cả thành viên'
              value={user.length}
              valueStyle={{
                color: '#cf1322',
              }}
              prefix={<ArrowUpOutlined />}
            />
          </Card>
        </Col>
      </div>

      <div className='flex flex-col md:flex-row'>
        <div className='w-full'>
          <Card>
            <Statistic
              title='Tổng chơi'
              value={formatMoney(statistic?.BET)}
              valueStyle={{
                color: '#3f8600',
              }}
              prefix={<ArrowUpOutlined />}
            />
          </Card>
        </div>
        <div className='w-full'>
          <Card>
            <Statistic
              title='Lợi nhuận'
              value={formatMoney(statistic?.PROFIT)}
              valueStyle={{
                color: '#3f8600',
              }}
              prefix={<ArrowUpOutlined />}
              
            />
          </Card>
        </div>

        <div className='w-full'>
          <Card>
            <Statistic
              title='Tổng nạp'
              value={formatMoney(statistic?.DEPOSIT)}
              valueStyle={{
                color: '#cf1322',
              }}
              prefix={<ArrowUpOutlined />}
              
            />
          </Card>
        </div>

        <div className='w-full'>
          <Card>
            <Statistic
              title='Tổng rút'
              value={formatMoney(statistic?.WITHDRAW)}
              valueStyle={{
                color: '#cf1322',
              }}
              prefix={<ArrowUpOutlined />}
              
            />
          </Card>
        </div>
      </div>

      <div className='my-4'>
        <DatePicker.RangePicker
          defaultValue={[moment(), moment()]}
          clearIcon={false}
          size='large'
          onChange={(e) => fetchStatisticByDate(e[0], e[1])}
        />
      </div>

      <div className='mt-6'>
        <Table
          title={() => <span className='text-xl font-semibold'>Thành viên mới</span>}
          columns={columns}
          dataSource={user}
        />
        <Table
          title={() => <span className='text-xl font-semibold'>Lịch sử chơi gần đây</span>}
          columns={columnsHistoryBet}
          dataSource={statistic?.HISTORY_BET}
        />
      </div>
    </div>
  );
};

export default Home;
