import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api';

export const getAll = createAsyncThunk('statistic/getAll', async (data, { rejectWithValue }) => {
  try {
    const response = await api.get('/dashboard/statistic');
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const getByDay = createAsyncThunk('statistic/getByDay', async (data, { rejectWithValue }) => {
  try {
    const response = await api.get(`/dashboard/statistic?from=${data.from}&to=${data.to}`);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const getSys = createAsyncThunk('statistic/getSys', async (data, { rejectWithValue }) => {
  try {
    const response = await api.get('/dashboard/sys');
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const setSys = createAsyncThunk('statistic/setSys', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post('/dashboard/sys', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const getStatisticAgent = createAsyncThunk('statistic/getStatisticAgent', async (data, { rejectWithValue }) => {
  try {
    const response = await api.get(`/dashboard/agent?username=${data}`);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

const StatisticSlice = createSlice({
  name: 'statistic',
  initialState: {
    data: [],
    isLoading: false,
  },
  reducers: {},
  extraReducers: {
    [getAll.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAll.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    [getAll.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});
export default StatisticSlice.reducer;
