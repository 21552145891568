import { unwrapResult } from '@reduxjs/toolkit';
import { Button, message, Modal, Radio, Select, Space, Table, TimePicker } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAll, updateBet, createBet, createMultiBet } from '../features/bet/betSlice';
import useWebSocket from 'react-use-websocket';
import { formatMoney } from '../utils';

const ControlPage = () => {
  const dispatch = useDispatch();
  const { isLoading, data } = useSelector((state) => state.bet);

  const [open, setOpen] = useState(false);
  const [createBetValue, setCreateBetValue] = useState('BUY');
  const [createBetSession, setCreateBetSession] = useState(moment());
  const [modalMulti, setModalMulti] = useState(false);
  const [createBetSessionMulti, setCreateBetSessionMulti] = useState(moment());
  const [createBetMultiAmount, setCreateBetMultiAmount] = useState(10);
  const [countDownData, setCountDownData] = useState({
    time: 30,
    type: 'order',
  });

  const [userBetBuy, setUserBetBuy] = useState([]);
  const [userBetSell, setUserBetSell] = useState([]);
  const [userBetBuyAmount, setUserBetBuyAmount] = useState(0);
  const [userBetSellAmount, setUserBetSellAmount] = useState(0);

  const DisabledTime = (now) => ({
    disabledHours: () => [...Array.from({ length: now.hour() }, (_, i) => i)],
    disabledMinutes: (selectedHour) => [
      ...Array.from({ length: selectedHour === now.hour() ? now.minute() : 0 }, (_, i) => i),
    ],
  });

  const { sendJsonMessage, lastJsonMessage } = useWebSocket(
    process.env.NODE_ENV === 'development' ? process.env.REACT_APP_SOCKET_URL_DEV : process.env.REACT_APP_SOCKET_URL,
  );

  const fetchBetData = async () => {
    await dispatch(getAll());
  };

  useEffect(() => {
    fetchBetData();
  }, []);

  const onChangeBet = async (id, type) => {
    try {
      await dispatch(updateBet({ id, type })).then(unwrapResult);
      fetchBetData();
      message.success('Update bet success');
    } catch (error) {
      message.error(`Update bet failed: ${error.message}`);
    }
  };

  useEffect(() => {
    if (lastJsonMessage !== null) {
      const { type, data } = lastJsonMessage;

      if (type === 'GET_PLAYER_LIST') {
        setUserBetBuy(data.filter((item) => item.type === 'buy'));
        setUserBetSell(data.filter((item) => item.type === 'sell'));
        setUserBetBuyAmount(data.reduce((acc, item) => (item.type === 'buy' ? acc + item.amount : acc), 0));
        setUserBetSellAmount(data.reduce((acc, item) => (item.type === 'sell' ? acc + item.amount : acc), 0));
      }

      if (type === 'allData') {
        setCountDownData({
          time: data.candleClose,
          type: data.type,
        });

        if (Number(data.candleClose) < 1 && data.type !== 'order') {
          setTimeout(() => {
            fetchBetData();
          }, 2000);
        }
      }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    const a = setInterval(() => {
      sendJsonMessage({ type: 'GET_PLAYER_LIST' });
    }, 2000);
    return () => clearInterval(a);
  }, []);

  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      key: '',
      width: '5%',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Kỳ chơi',
      dataIndex: 'session',
      key: 'session',
    },
    {
      title: 'Tăng / Giảm',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => {
        return text === 'BUY' ? (
          <span className='bg-green-400 px-4 py-1 text-white rounded-md'>Tăng</span>
        ) : (
          <span className='bg-red-400 px-4 py-1 text-white rounded-md'>Giảm</span>
        );
      },
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Hành động',
      dataIndex: '',
      key: '',
      width: '20%',
      render: (text, record, index) => {
        return (
          <Radio.Group onChange={(e) => onChangeBet(record.id, e.target.value)} value={record.type}>
            <Space>
              <Radio value='BUY'>
                <span className='bg-green-400 px-4 py-1 text-white rounded-md'>Tăng</span>
              </Radio>
              <Radio value='SELL'>
                <span className='bg-red-400 px-4 py-1 text-white rounded-md'>Giảm</span>
              </Radio>
            </Space>
          </Radio.Group>
        );
      },
    },
  ];

  const onCreateBet = async () => {
    try {
      const obj = {
        session: createBetSession.format('YYYYMMDDHHmm'),
        type: createBetValue,
        note: 'Admin tạo',
      };

      await dispatch(createBet(obj)).then(unwrapResult);
      fetchBetData();
      message.success('Create bet success');
      setOpen(false);
    } catch (error) {
      message.error(`Create bet failed: ${error}`);
    }
  };

  const onCreateMultiBet = async () => {
    try {
      const obj = {
        session: createBetSessionMulti.valueOf(),
        amount: createBetMultiAmount,
      };
      await dispatch(createMultiBet(obj)).then(unwrapResult);
      fetchBetData();
      message.success('Create multi bet success');
      setModalMulti(false);
    } catch (error) {
      message.error(`Create bet failed: ${error}`);
    }
  };

  const betColumns = [
    {
      title: 'STT',
      dataIndex: '',
      key: '',
      width: '5%',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'username',
      key: 'username',
    },

    {
      title: 'Số dư',
      dataIndex: 'balance',
      key: 'balance',
      render: (text, record) => {
        return <span>{formatMoney(text)}</span>;
      },
    },

    {
      title: 'Số tiền cược',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => {
        return <span>{formatMoney(text)}</span>;
      },
    },
    {
      title: 'Loại',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => {
        return text === 'buy' ? (
          <span className='bg-green-400 px-4 py-1 text-white rounded-md'>Tăng</span>
        ) : (
          <span className='bg-red-400 px-4 py-1 text-white rounded-md'>Giảm</span>
        );
      },
    },
  ];

  return (
    <div className='h-full md:h-screen'>
      <div className='flex justify-center'>
        <span className='font-semibold text-xl'>
          {countDownData.type === 'order' ? 'Đặt lệnh' : 'Chờ kết quả'}:{' '}
          <span className='text-blue-400'>{countDownData.time}s</span>
        </span>
      </div>
      <div className='flex justify-end my-2'>
        <Space>
          <Button onClick={() => setModalMulti(true)} type='primary'>
            Thêm nhiều kỳ chơi
          </Button>
          <Button onClick={() => setOpen(true)} type='primary'>
            Thêm
          </Button>
        </Space>
      </div>
      <Modal
        centered
        title='Thêm nhiều kỳ chơi'
        open={modalMulti}
        onCancel={() => setModalMulti(false)}
        onOk={onCreateMultiBet}
        width={800}
        okText={`Thêm ${createBetMultiAmount} kỳ chơi`}
        confirmLoading={isLoading}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div className='flex flex-col'>
          <div>
            <span className='mr-4'>Kỳ chơi bắt đầu: </span>
            <TimePicker
              size='large'
              className='w-1/2'
              placeholder='Chọn kỳ chơi bắt đầu'
              disabledTime={DisabledTime}
              defaultValue={moment(moment(), 'HH:mm')}
              format={'HH:mm'}
              showNow={true}
              clearIcon={null}
              onChange={(e) => setCreateBetSessionMulti(e)}
            />
          </div>

          <div className='my-6'>
            <span className='mr-9'>Số lượng kỳ: </span>
            <Select size='large' className='w-1/2' defaultValue='10' onChange={(e) => setCreateBetMultiAmount(e)}>
              <Select.Option value='10'>10</Select.Option>
              <Select.Option value='20'>20</Select.Option>
              <Select.Option value='30'>30</Select.Option>
              <Select.Option value='40'>40</Select.Option>
              <Select.Option value='50'>50</Select.Option>
              <Select.Option value='60'>60</Select.Option>
              <Select.Option value='70'>70</Select.Option>
              <Select.Option value='80'>80</Select.Option>
              <Select.Option value='90'>90</Select.Option>
              <Select.Option value='100'>100</Select.Option>
            </Select>
          </div>
        </div>
      </Modal>
      <Modal centered title='Thêm kỳ chơi' open={open} onCancel={() => setOpen(false)} onOk={onCreateBet}>
        <div className='flex flex-col'>
          <div>
            <span className='mr-4'>Kỳ chơi: </span>
            <TimePicker
              className='w-1/2'
              placeholder='Chọn kỳ chơi'
              disabledTime={DisabledTime}
              defaultValue={moment(moment(), 'HH:mm')}
              format={'HH:mm'}
              showNow={false}
              onChange={(e) => setCreateBetSession(e)}
            />
          </div>

          <div className='mt-6'>
            <span className='mr-4'>Kỳ chơi: </span>
            <Radio.Group defaultValue='BUY' onChange={(e) => setCreateBetValue(e.target.value)}>
              <Space>
                <Radio value='BUY'>Tăng</Radio>
                <Radio value='SELL'>Giảm</Radio>
              </Space>
            </Radio.Group>
          </div>
        </div>
      </Modal>
      <Table loading={isLoading} columns={columns} dataSource={data} />

      <div className='flex flex-col md:flex-row'>
        <div className='w-full md:w-1/2 mx-2 my-12 md:my-0'>
          <div className='flex justify-between'>
            <span className='font-semibold text-base md:text-xl'>Người chọn Tăng</span>
            <span className='font-semibold text-base md:text-xl'>Tổng số tiền: {formatMoney(userBetBuyAmount)}</span>
          </div>
          <Table pagination={{ pageSize: 20 }} columns={betColumns} dataSource={userBetBuy} />
        </div>
        <div className='w-full md:w-1/2 mx-2 my-12 md:my-0'>
          <div className='flex justify-between'>
            <span className='font-semibold text-base md:text-xl'>Người chọn Giảm</span>
            <span className='font-semibold text-base md:text-xl'>Tổng số tiền: {formatMoney(userBetSellAmount)}</span>
          </div>
          <Table pagination={{ pageSize: 20 }} columns={betColumns} dataSource={userBetSell} />
        </div>
      </div>
    </div>
  );
};

export default ControlPage;
