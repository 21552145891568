import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api';

export const getAll = createAsyncThunk('bet/getAll', async (data, { rejectWithValue }) => {
  try {
    const response = await api.get('/bet');
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const updateBet = createAsyncThunk('bet/updateBet', async (data, { rejectWithValue }) => {
  try {
    const response = await api.put('/bet', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const createBet = createAsyncThunk('bet/createBet', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post('/bet', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const createMultiBet = createAsyncThunk('bet/createMultiBet', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post('/bet/multi', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

const BetSlice = createSlice({
  name: 'bet',
  initialState: {
    data: [],
    isLoading: false,
  },
  reducers: {},
  extraReducers: {
    [getAll.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAll.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    [getAll.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [createMultiBet.pending]: (state, action) => {
      state.isLoading = true;
    },
    [createMultiBet.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [createMultiBet.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});
export default BetSlice.reducer;
