import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userSlice from '../features/user/userSlice';
import bankSlice from '../features/bank/bankSlice';
import StatisticSlice from '../features/statistic/statisticSlice';
import BetSlice from '../features/bet/betSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { setStore } from '../api';

const persistConfig = {
  key: 'root',
  storage,
};
const rootReducer = combineReducers({
  user: userSlice,
  bank: bankSlice,
  statistic: StatisticSlice,
  bet: BetSlice,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export const persistor = persistStore(store);
setStore(store);
