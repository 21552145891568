import { unwrapResult } from '@reduxjs/toolkit';
import { Button, DatePicker, Input, message, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAgent } from '../features/user/userSlice';
import { formatMoney } from '../utils';

const AgentPage = () => {
  const dispatch = useDispatch();
  const agentData = useSelector((state) => state.user.agentData);
  const [data, setData] = useState(agentData);
  const [dataShow, setDataShow] = useState(data);
  const fetchAgentUser = async () => {
    try {
      const data = await dispatch(getAllAgent()).then(unwrapResult);
      setData(data);
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    fetchAgentUser();
  }, []);

  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      key: '',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Số dư',
      dataIndex: 'balance',
      key: 'balance',
      render: (text) => `${formatMoney(text)}`,
    },
    {
      title: 'Tổng giới thiệu',
      dataIndex: 'totalRef',
      key: 'totalRef',
    },
  ];

  const onHandleSearch = (value) => {
    if (!value) {
      setDataShow(data);
    } else {
      const newData = data.filter((item) => {
        return item.username.toLowerCase().includes(value.toLowerCase());
      });
      setDataShow(newData);
    }
  };

  return (
    <div className='h-screen'>
      <div className='flex py-2'>
        <Input allowClear placeholder='Tìm kiếm bằng username' onChange={(e) => onHandleSearch(e.target.value)} />
      </div>
      <Table rowKey={(e) => e.id} columns={columns} dataSource={dataShow} />
    </div>
  );
};

export default AgentPage;
