export const COLORS = {
  HEADER: '#19191A',
  HEADER_TEXT: '#835D33',
};

export const TRANSACTION_STATUS = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  CANCEL: 'CANCEL',
};

export const TRANSACTION_TYPE = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW',
  BET: 'BET',
};

export const USER_ROLE = {
  USER: 'USER',
  ADMIN: 'ADMIN',
  AGENT: 'AGENT',
};