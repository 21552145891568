import { FormOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Statistic,
  Table,
  Tag,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UpdateUserInfo from '../components/UpdateUserInfo';
import {
  changePasswordUser,
  deactiveUser,
  decreaseBalance,
  getAllUser,
  increaseBalance,
  deleteUser,
} from '../features/user/userSlice';
import { formatMoney } from '../utils';
import { USER_ROLE } from '../utils/constants';
const UserPage = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user.data);
  const [user, setUser] = useState(userState);
  const [dataShow, setDataShow] = useState(user);
  const [userSelected, setUserSelected] = useState({});
  const [modalUpdateUser, setModalUpdateUser] = useState(false);
  const [modalChangePass, setModalChangePass] = useState(false);
  const [modalIncrease, setModalIncrease] = useState(false);
  const [modalDecrease, setModalDecrease] = useState(false);
  const [formChangePass] = Form.useForm();
  const [formIncreaseBalance] = Form.useForm();
  const [formDecreaseBalance] = Form.useForm();


  const fetchUser = async () => {
    try {
      const data = await dispatch(getAllUser()).then(unwrapResult);
      setUser(data);
      setDataShow(data);
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);


  const onDeactiveUser = async (id) => {
    try {
      await dispatch(deactiveUser({ id })).then(unwrapResult);
      message.success('Thay đổi trạng thái thành công');
      await dispatch(getAllUser()).then(unwrapResult);
    } catch (error) {
      message.error(error);
    }
  };

  const onFinishChangePass = async (values) => {
    try {
      const data = {
        password: values.password,
        id: userSelected.id,
      };
      await dispatch(changePasswordUser(data)).then(unwrapResult);
      message.success('Đổi mật khẩu thành công');
      formChangePass.resetFields();
      setModalChangePass(false);
    } catch (error) {
      message.error(error);
    }
  };

  const onHandleBalance = async (values) => {
    try {
      const data = {
        amount: values.amount,
        id: userSelected.id,
      };
      await dispatch(increaseBalance(data)).then(unwrapResult);
      message.success('Tăng số dư thành công');
      formIncreaseBalance.resetFields();
      setModalIncrease(false);
      fetchUser();
    } catch (error) {
      message.error(error);
    }
  };

  const onDeleteUser = async (id) => {
    try {
      await dispatch(deleteUser(id)).then(unwrapResult);
      fetchUser();
      message.success('Xóa thành công');
    } catch (error) {
      message.error(error);
    }
  };

  const onHandleDecrease = async (values) => {
    try {
      const data = {
        amount: values.amount,
        id: userSelected.id,
      };
      await dispatch(decreaseBalance(data)).then(unwrapResult);
      message.success('Giảm số dư thành công');
      formDecreaseBalance.resetFields();
      setModalDecrease(false);
      fetchUser();
    } catch (error) {
      message.error(error);
    }
  };

  const handleMenu = (e) => {
    return (
      <Menu
        items={[
          {
            label: (
              <button
                className='w-full '
                onClick={() => {
                  onDeactiveUser(e.id);
                }}
              >
                {e.isActive ? 'Khóa' : 'Mở khóa'}
              </button>
            ),
            key: '1',
          },
          {
            label: (
              <button
                className='w-full '
                onClick={() => {
                  setUserSelected(e);
                  setModalChangePass(true);
                }}
              >
                Đổi mật khẩu
              </button>
            ),
            key: '2',
          },
          {
            label: (
              <button
                className='w-full '
                onClick={() => {
                  setUserSelected(e);
                  setModalIncrease(true);
                }}
              >
                Tăng số dư
              </button>
            ),
            key: '3',
          },
          {
            label: (
              <button
                className='w-full '
                onClick={() => {
                  setUserSelected(e);
                  setModalDecrease(true);
                }}
              >
                Giảm số dư
              </button>
            ),
            key: '4',
          },
          {
            label: (
              <button onClick={() => setUserSelected(e)}>
                <Popconfirm
                  title='Bạn có chắc muốn xóa?'
                  onConfirm={() => onDeleteUser(userSelected.id)}
                  okText='Xác nhận'
                  cancelText='Quay lại'
                >
                  <button className='w-full'>Xóa người dùng</button>
                </Popconfirm>
              </button>
            ),
            key: '5',
          },
        ]}
      />
    );
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      key: '',
      render: (text, record, index) => index + 1,
      width: '1%',
    },
    {
      title: 'Tài khoản',
      dataIndex: 'username',
      key: 'username',
      width: '10%',
    },
    {
      title: 'Họ và tên',
      dataIndex: 'fullname',
      key: 'fullname',
      width: '15%',
    },

    {
      title: 'Số dư',
      key: 'balance',
      dataIndex: 'balance',
      render: (text) => {
        return <Tag color='blue'>{formatMoney(text)}</Tag>;
      },
      width: '10%',
      onFilter: (value, record) => record.balance.indexOf(value) === 0,
      sorter: (a, b) => a.balance - b.balance,
    },
    {
      title: 'Mã giới thiệu',
      dataIndex: 'ref',
      key: 'ref',
      width: '10%',
    },
    {
      title: 'Hoạt động',
      key: 'isActive',
      dataIndex: 'isActive',
      render: (text, record) => {
        if (text) {
          return <Tag color='green'>Hoạt động</Tag>;
        } else {
          return <Tag color='red'>Không hoạt động</Tag>;
        }
      },
      width: '10%',
      onFilter: (value, record) => record.isActive === value,
      filters: [
        {
          text: 'Đang hoạt động',
          value: true,
        },
        {
          text: 'Không hoạt động',
          value: false,
        },
      ],
    },
    {
      title: 'Giấy tờ',
      key: '',
      dataIndex: '',
      render: (text, record) => {
        if (record.id_front && record.id_back) {
          return <Tag color='purple'>Đầy đủ</Tag>;
        } else {
          return <Tag color='orange'>Thiếu thông tin</Tag>;
        }
      },
      width: '10%',
      onFilter: (value, record) => record.id_front && record.id_back,
      filters: [
        {
          text: 'Đầy đủ',
          value: true,
        },
        {
          text: 'Thiếu thông tin',
          value: false,
        },
      ],
    },
    {
      title: 'ROLE',
      key: 'role',
      dataIndex: 'role',
      render: (role) => {
        if (role === USER_ROLE.ADMIN) {
          return <Tag color='orange'>Quản trị viên</Tag>;
        }
        if (role === USER_ROLE.AGENT) {
          return <Tag color='purple'>Đại lý</Tag>;
        }
        if (role === USER_ROLE.USER) {
          return <Tag color='green'>Người dùng</Tag>;
        } else {
          return <Tag color='orange'>{role}</Tag>;
        }
      },
      width: '10%',
    },
    {
      title: 'Xác minh',
      key: 'isVerified',
      dataIndex: 'isVerified',
      render: (text, record) => {
        if (text) {
          return <Tag color='green'>Đã xác minh</Tag>;
        } else {
          return <Tag color='red'>Chưa xác minh</Tag>;
        }
      },
      width: '5%',
      onFilter: (value, record) => record.isVerified === value,
      filters: [
        {
          text: 'Đã xác minh',
          value: true,
        },
        {
          text: 'Chưa xác minh',
          value: false,
        },
      ],
    },
    {
      title: 'Ngày tạo',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (text, record) => moment(text).format('HH:mm DD/MM/YYYY'),
      width: '10%',
      onFilter: (value, record) => record.createdAt.indexOf(value) === 0,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: 'Hành động',
      key: '',
      dataIndex: '',
      render: (text, record) => (
        <Space size='middle'>
          <Dropdown overlay={() => handleMenu(record)} trigger={['click']}>
            <Button type='primary'>
              <UserSwitchOutlined />
            </Button>
          </Dropdown>
          <Button
            type='primary'
            onClick={() => {
              setUserSelected(record);
              setModalUpdateUser(true);
            }}
          >
            <FormOutlined />
          </Button>
        </Space>
      ),
      width: '10%',
    },
  ];

  const onHandleSearch = (value) => {
    if (!value) {
      setDataShow(user);
    } else {
      const newData = user.filter((item) => {
        return item.username.toLowerCase().includes(value.toLowerCase());
      });
      setDataShow(newData);
    }
  };

  return (
    <div className='h-screen'>
      <div className='flex py-2'>
        <Input allowClear placeholder='Tìm kiếm bằng username' onChange={(e) => onHandleSearch(e.target.value)} />
      </div>
      <Table rowKey={(record) => record.id} columns={columns} dataSource={dataShow} />

      <Modal
        title='Thay đổi thông tin người dùng'
        open={modalUpdateUser}
        footer={null}
        centered
        width={1200}
        onCancel={() => setModalUpdateUser(false)}
      >
        <UpdateUserInfo user={userSelected} />
      </Modal>

      <Modal
        title='Đổi mật khẩu'
        open={modalChangePass}
        footer={null}
        centered
        width={800}
        onCancel={() => setModalChangePass(false)}
      >
        <Form form={formChangePass} name='basic' onFinish={onFinishChangePass}>
          <Form.Item
            label='Mật khẩu mới'
            name='password'
            rules={[
              {
                required: true,
                message: 'Please input password!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title='Tăng số dư'
        open={modalIncrease}
        footer={null}
        centered
        width={800}
        onCancel={() => setModalIncrease(false)}
      >
        <Row className='mb-4 mx-2'>
          <Col span={12}>
            <Statistic title='Tên người dùng' value={userSelected.username} />
          </Col>

          <Col span={12}>
            <Statistic title='Số dư hiện tại' value={userSelected.balance} />
          </Col>
        </Row>
        <Form form={formIncreaseBalance} name='basic' onFinish={onHandleBalance}>
          <Form.Item
            label='Số lượng'
            name='amount'
            rules={[
              {
                required: true,
                message: 'Please input amount!',
              },
            ]}
          >
            <Input type='number' />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type='primary' htmlType='submit'>
              Tăng số dư
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title='Giảm số dư'
        open={modalDecrease}
        footer={null}
        centered
        width={800}
        onCancel={() => setModalDecrease(false)}
      >
        <Row className='mb-4 mx-2'>
          <Col span={12}>
            <Statistic title='Tên người dùng' value={userSelected.username} />
          </Col>

          <Col span={12}>
            <Statistic title='Số dư hiện tại' value={userSelected.balance} />
          </Col>
        </Row>
        <Form form={formDecreaseBalance} name='basic' onFinish={onHandleDecrease}>
          <div className='flex'>
            <Form.Item
              className='w-full'
              label='Số lượng'
              name='amount'
              rules={[
                {
                  required: true,
                  message: 'Please input amount!',
                },
                {
                  validator: (rule, value) => {
                    if (value > userSelected.balance) {
                      return Promise.reject('Vượt quá số dư');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input type='number' />
            </Form.Item>

            <Button
              onClick={() => {
                formDecreaseBalance.setFieldsValue({
                  amount: userSelected.balance,
                });
              }}
            >
              Tất cả
            </Button>
          </div>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type='primary' htmlType='submit'>
              Giảm số dư
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserPage;
