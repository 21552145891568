import { MailOutlined, PieChartOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
const { Header, Content, Footer, Sider } = Layout;

const App = () => {
  const navigate = useNavigate();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem('Bảng điều khiển', '/', <PieChartOutlined />),
    getItem('Người dùng', '/user', <UserOutlined />),
    getItem('Giao dịch', 'sub1', <MailOutlined />, [
      getItem('Nạp', '/transaction/deposit'),
      getItem('Rút', '/transaction/withdraw'),
      getItem('Chơi', '/transaction/bet'),
    ]),
    getItem('Phương thức thanh toán', '/payment-method', <UserOutlined />),
    getItem('Điều khiển', '/control', <UserOutlined />),
    getItem('Bảo trì', '/maintenace', <UserOutlined />),
    getItem('Đại lý', '/agent', <UserOutlined />),
  ];
  return (
    <Layout>
      <Sider breakpoint='lg' collapsedWidth='0'>
        <div className='logo mt-14' />
        <Menu
          mode='inline'
          defaultSelectedKeys={window.location.pathname}
          defaultOpenKeys={['sub1']}
          theme='dark'
          items={items}
          onClick={(e) => navigate(e.key)}
        />
      </Sider>
      <Layout>
        <Header />
        <Content className='m-5'>
          <div className='h-full'>
            <Outlet />
          </div>
        </Content>

        <div className='mt-12'>
          <Footer className='text-center'>Ant Design ©2022 Created by Juno Dev</Footer>
        </div>
      </Layout>
    </Layout>
  );
};

export default App;
