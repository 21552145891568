import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Form, Input, message, Modal, Select, Space, Table, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createNew, deleteBank, getAll, updateInfo } from '../features/bank/bankSlice';
const PaymentMethod = () => {
  const dispatch = useDispatch();
  const [banks, setBanks] = useState([]);
  const [form] = Form.useForm();
  const [formUpdate] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [idSelected, setIdSelected] = useState('');

  const onTypeChange = (value) => {
    switch (value) {
      case 'BANK':
        form.setFieldsValue({
          type: 'BANK',
        });
        return;
      case 'USDT':
        form.setFieldsValue({
          type: 'USDT',
        });
        return;
    }
  };

  const getAllBank = async () => {
    try {
      const data = await dispatch(getAll()).then(unwrapResult);
      setBanks(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBank();
    form.setFieldValue('type', 'BANK');
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: '',
      key: '',
      width: '5%',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Loại',
      dataIndex: 'type',
      key: 'type',
      render: (text) => {
        switch (text) {
          case 'BANK':
            return <Tag color='green'>Ngân hàng</Tag>;
          case 'USDT':
            return <Tag color='blue'>USDT</Tag>;
        }
      },
    },
    {
      title: 'Tên ngân hàng',
      dataIndex: 'bank_name',
      key: 'bank_name',
    },
    {
      title: 'Số tài khoản',
      dataIndex: 'bank_number',
      key: 'bank_number',
    },

    {
      title: 'Tên người nhận',
      dataIndex: 'bank_holder',
      key: 'bank_holder',
    },

    {
      title: 'Địa chỉ USDT',
      dataIndex: 'USDT_address',
      key: 'USDT_address',
    },

    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => {
        return moment(text).format('HH:mm DD/MM/YYYY');
      },
    },

    {
      title: 'Hành động',
      key: 'action',
      width: '10%',
      render: (_, record) => (
        <Space>
          {/* <Button
            type='primary'
            onClick={() => {
              formUpdate.setFieldsValue(record);
              setIdSelected(record.id);
              setModalUpdate(true);
            }}
          >
            Chỉnh sửa
          </Button> */}
          <Button danger onClick={() => onDelete(record.id)}>
            Xoá
          </Button>
        </Space>
      ),
    },
  ];

  const onHandleCreate = async (values) => {
    try {
      await dispatch(createNew(values)).then(unwrapResult);
      setModal(false);
      message.success('Tạo mới thành công');
      getAllBank();
      form.resetFields();
      form.setFieldValue('type', 'BANK');
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleUpdate = async (values) => {
    try {
      values.id = idSelected;
      await dispatch(updateInfo(values)).then(unwrapResult);
      message.success('Sửa mới thành công');
      getAllBank();
      setModalUpdate(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onDelete = async (id) => {
    try {
      await dispatch(deleteBank({ id })).then(unwrapResult);
      message.success('Xóa thành công');
      getAllBank();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='h-screen'>
      <div className='flex w-full justify-end my-2'>
        <Button type='primary' onClick={() => setModal(true)}>
          Thêm phương thức thanh toán
        </Button>
      </div>
      <Table rowKey={(e) => e.id} columns={columns} dataSource={banks} />

      <Modal
        centered
        width={800}
        title='Thêm thông tin thanh toán'
        open={modal}
        footer={null}
        onCancel={() => setModal(false)}
      >
        <Form
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 24,
          }}
          labelAlign='left'
          form={form}
          onFinish={onHandleCreate}
        >
          <Form.Item
            name='type'
            label='Loại'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder='Select a option and change input text above' onChange={onTypeChange}>
              <Select.Option value='BANK'>BANK</Select.Option>
              <Select.Option value='USDT'>USDT</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
            {({ getFieldValue }) =>
              getFieldValue('type') === 'USDT' ? (
                <>
                  <Form.Item label='Địa chỉ USDT' name='USDT_address'>
                    <Input />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    label='Tên ngân hàng'
                    name='bank_name'
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    label='Số tài khoản'
                    name='bank_number'
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    label='Tên người nhận'
                    name='bank_holder'
                  >
                    <Input />
                  </Form.Item>
                </>
              )
            }
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Thêm
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        centered
        width={800}
        title='Sửa thông tin thanh toán'
        open={modalUpdate}
        footer={null}
        onCancel={() => setModalUpdate(false)}
      >
        <Form
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 24,
          }}
          labelAlign='left'
          form={formUpdate}
          onFinish={onHandleUpdate}
        >
          <Form.Item label='Tên ngân hàng' name='bank_name'>
            <Input />
          </Form.Item>
          <Form.Item label='Số tài khoản' name='bank_number'>
            <Input />
          </Form.Item>
          <Form.Item label='Tên người nhận' name='bank_holder'>
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Sửa
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PaymentMethod;
