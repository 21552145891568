import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api';

export const getAllUser = createAsyncThunk('user/getAllUser', async (data, { rejectWithValue }) => {
  try {
    const response = await api.get('/dashboard/user', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const getAllAgent = createAsyncThunk('user/getAllAgent', async (data, { rejectWithValue }) => {
  try {
    const response = await api.get('/dashboard/agent', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const getNewUser = createAsyncThunk('user/getNewUser', async (data, { rejectWithValue }) => {
  try {
    const response = await api.get('/dashboard/newUser', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const updateUser = createAsyncThunk('user/updateUser', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post('/dashboard/updateUser', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const deactiveUser = createAsyncThunk('user/deactiveUser', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post('/dashboard/deactiveUser', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const changePasswordUser = createAsyncThunk('user/changePasswordUser', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post('/dashboard/changePasswordUser', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const decreaseBalance = createAsyncThunk('user/decreaseBalance', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post('/dashboard/decreaseBalance', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const increaseBalance = createAsyncThunk('user/increaseBalance', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post('/dashboard/increaseBalance', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const acceptDeposit = createAsyncThunk('user/acceptDeposit', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post('/dashboard/acceptDeposit', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const rejectDeposit = createAsyncThunk('user/rejectDeposit', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post('/dashboard/rejectDeposit', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const acceptWithdraw = createAsyncThunk('user/acceptWithdraw', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post('/dashboard/acceptWithdraw', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const rejectWithdraw = createAsyncThunk('user/rejectWithdraw', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post('/dashboard/rejectWithdraw', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const signin = createAsyncThunk('user/signin', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post('/dashboard/signin', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const deleteUser = createAsyncThunk('user/deleteUser', async (data, { rejectWithValue }) => {
  try {
    const response = await api.delete(`/dashboard/deleteUser?id=${data}`);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

const UserSlice = createSlice({
  name: 'user',
  initialState: {
    data: [],
    agentData: [],
    accessToken: '',
    user: {},
    isLoading: false,
  },
  reducers: {
    logout: (state) => {
      state.accessToken = '';
      state.user = {};
    },
  },
  extraReducers: {
    [getAllUser.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAllUser.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    [getAllUser.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [getAllAgent.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAllAgent.fulfilled]: (state, action) => {
      state.agentData = action.payload;
      state.isLoading = false;
    },
    [getAllAgent.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [signin.pending]: (state, action) => {
      state.isLoading = true;
    },
    [signin.fulfilled]: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
      state.isLoading = false;
    },
    [signin.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});
export default UserSlice.reducer;
export const { logout } = UserSlice.actions;