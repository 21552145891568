import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Form, Input, message, Select } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getSys, setSys } from '../features/statistic/statisticSlice';

const MaintenancePage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const fetchData = async () => {
    const data = await dispatch(getSys()).then(unwrapResult);
    form.setFieldsValue({
      all: data.all,
      bet: data.bet,
      deposit: data.deposit,
      withdraw: data.withdraw,
      register: data.register,
      forgot: data.forgot,
      minDeposit: data.minDeposit,
      minWithdraw: data.minWithdraw,
      feeWithdraw: data.feeWithdraw,
      telegram: data.telegram,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onFinish = async (values) => {
    try {
      const newData = {
        ...values,
        minDeposit: parseInt(values.minDeposit),
        minWithdraw: parseInt(values.minWithdraw),
        feeWithdraw: parseInt(values.feeWithdraw),
      };
      await dispatch(setSys(newData)).then(unwrapResult);
      message.success('Cập nhật thành công');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='mt-6 h-screen'>
      <Form
        form={form}
        name='basic'
        labelCol={{
          span: 3,
        }}
        wrapperCol={{
          span: 16,
        }}
        labelAlign='left'
        onFinish={onFinish}
      >
        <Form.Item label='Toàn bộ web' name='all'>
          <Select className='w-full'>
            <Select.Option value={true}>Bật</Select.Option>
            <Select.Option value={false}>Tắt</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label='Game trực tiếp' name='bet'>
          <Select className='w-full'>
            <Select.Option value={true}>Bật</Select.Option>
            <Select.Option value={false}>Tắt</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label='Nạp' name='deposit'>
          <Select className='w-full'>
            <Select.Option value={true}>Bật</Select.Option>
            <Select.Option value={false}>Tắt</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label='Rút' name='withdraw'>
          <Select className='w-full'>
            <Select.Option value={true}>Bật</Select.Option>
            <Select.Option value={false}>Tắt</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label='Đăng ký tài khoản mới' name='register'>
          <Select className='w-full'>
            <Select.Option value={true}>Bật</Select.Option>
            <Select.Option value={false}>Tắt</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label='Quên mật khẩu' name='forgot'>
          <Select className='w-full'>
            <Select.Option value={true}>Bật</Select.Option>
            <Select.Option value={false}>Tắt</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label='Min nạp' name='minDeposit'>
          <Input type='number' />
        </Form.Item>

        <Form.Item label='Min rút' name='minWithdraw'>
          <Input type='number' />
        </Form.Item>

        <Form.Item label='Phí rút (%)' name='feeWithdraw'>
          <Input type='number' />
        </Form.Item>

        <Form.Item label='Telegram hỗ trợ' name='telegram'>
          <Input type='text' />
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit'>
            Cập nhật
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default MaintenancePage;
