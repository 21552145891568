import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Checkbox, Descriptions, Form, Image, Input, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllUser, updateUser } from '../features/user/userSlice';
import { formatMoney } from '../utils';
const UpdateUserInfo = ({ user }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isActiveStatus, setIsActiveStatus] = useState(user.isActive);
  const [isVerifiedStatus, setIsVerifiedStatus] = useState(user.isVerified);
  const [userRole, setUserRole] = useState(user.role);
  useEffect(() => {
    form.setFieldsValue(user);

    setIsActiveStatus(user.isActive);
    setIsVerifiedStatus(user.isVerified);
    setUserRole(user.role);
  }, [user]);

  const onFinish = async (values) => {
    try {
      if (values.isActive !== isActiveStatus) {
        values.isActive = isActiveStatus;
      }

      if (values.isVerified !== isVerifiedStatus) {
        values.isVerified = isVerifiedStatus;
      }

      const data = {
        ...values,
        id: user.id,
        isActive: isActiveStatus,
        isVerified: isVerifiedStatus,
        role: userRole,
      };

      await dispatch(updateUser(data)).then(unwrapResult);
      await dispatch(getAllUser()).then(unwrapResult);
      message.success('Cập nhật thông tin thành công');
    } catch (error) {
      message.error(error);
    }
  };

  return (
    <div className='flex w-sreen'>
      <div className='w-1/2'>
        <Form
          form={form}
          name='basic'
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          labelAlign='left'
          onFinish={onFinish}
        >
          <Form.Item label='Hoạt động' name='isActive'>
            <Checkbox checked={isActiveStatus} onChange={(e) => setIsActiveStatus(e.target.checked)} />
          </Form.Item>

          <Form.Item label='Xác minh' name='isVerified'>
            <Checkbox checked={isVerifiedStatus} onChange={(e) => setIsVerifiedStatus(e.target.checked)} />
          </Form.Item>

          <Form.Item label='Chức vụ' name='role'>
            <Select onChange={(e) => setUserRole(e)}>
              <Select.Option value='USER'>Người dùng</Select.Option>
              <Select.Option value='AGENT'>Đại lý</Select.Option>
              <Select.Option value='ADMIN'>Quản trị viên</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label='Họ và tên'
            name='fullname'
            rules={[
              {
                required: true,
                message: 'Please input your fullname!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Số điện thoại'
            name='phone'
            rules={[
              {
                required: true,
                message: 'Please input your phone!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label='Tên tài khoản' name='bank_holder'>
            <Input />
          </Form.Item>

          <Form.Item label='Số tài khoản' name='bank_number'>
            <Input />
          </Form.Item>

          <Form.Item label='Tên ngân hàng' name='bank_name'>
            <Input />
          </Form.Item>

          <Form.Item label='Số CMND' name='id_number'>
            <Input />
          </Form.Item>

          <Form.Item label='CMND mặt trước' name='id_front'>
            <Input />
          </Form.Item>

          <Form.Item label='CMND mặt sau' name='id_back'>
            <Input />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div className='w-1/2 ml-10'>
        <Descriptions size='large'>
          <Descriptions.Item span={4} label='CMND mặt trước'>
            {user.id_front !== '' ? (
              <Image className='rounded-xl' width={100} src={user.id_front} alt='' />
            ) : (
              'Chưa cập nhật'
            )}
          </Descriptions.Item>

          <Descriptions.Item span={4} label='CMND mặt sau'>
            {user.id_front !== '' ? (
              <Image className='rounded-xl' width={100} src={user.id_back} alt='' />
            ) : (
              'Chưa cập nhật'
            )}
          </Descriptions.Item>

          <Descriptions.Item span={4} label='Tổng chơi'>
            {formatMoney(user.amountSpent)}
          </Descriptions.Item>
          <Descriptions.Item span={4} label='Tổng nạp'>
            {formatMoney(user.amountSpent)}
          </Descriptions.Item>
          <Descriptions.Item span={4} label='Tổng rút'>
            {formatMoney(user.amountSpent)}
          </Descriptions.Item>
          <Descriptions.Item span={4} label='Tổng thắng'>
            {formatMoney(user.amountWon)}
          </Descriptions.Item>
          <Descriptions.Item span={4} label='Tổng thua'>
            {formatMoney(user.amountLost)}
          </Descriptions.Item>
          <Descriptions.Item span={4} label='Số dư ví'>
            {formatMoney(user.balance)}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

export default UpdateUserInfo;
