import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import UserPage from '../pages/User';
import PaymentMethod from '../pages/PaymentMethod';
import DepositPage from '../pages/Deposit';
import BetPage from '../pages/Bet';
import WithdrawPage from '../pages/Withdraw';
import LoginPage from '../pages/LoginPage';
import AgentPage from '../pages/Agent';
import MaintenancePage from '../pages/Maintenance';
import ControlPage from '../pages/Control';
const Routers = () => {
  return (
    <Routes>
      <Route path='/' element={<MainLayout />}>
        <Route index element={<Home />} />
        <Route path='/user' element={<UserPage />} />
        <Route path='/payment-method' element={<PaymentMethod />} />
        <Route path='/transaction/deposit' element={<DepositPage />} />
        <Route path='/transaction/withdraw' element={<WithdrawPage />} />
        <Route path='/transaction/bet' element={<BetPage />} />
        <Route path='/agent' element={<AgentPage />} />
        <Route path='/maintenace' element={<MaintenancePage />} />
        <Route path='/control' element={<ControlPage />} />
        <Route path='*' element={<NotFound />} />
      </Route>
      <Route path='/login' element={<LoginPage />} />
    </Routes>
  );
};

export default Routers;
